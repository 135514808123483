import React from 'react'
import { CreatedBy } from '@accodeing/gatsby-theme-heimr'
import { Link } from 'gatsby'

import SiteMetadata from 'query/SiteMetadata'

const Footer = () => {
  const { name, city, phoneNumber, email, owner } = SiteMetadata()
  return (
    <oma-grid-row>
      <footer className='footer'>
        <p>All works © Oskar Jonsson | {name}</p>
        <p>
          {owner}, {city} <br /> <oma-link to={phoneNumber} class='link' />,{' '}
          <oma-link to={email} class='link' />
        </p>
        <Link to='/cookies'>Cookies</Link>
        <CreatedBy />
      </footer>
    </oma-grid-row>
  )
}

export default Footer
