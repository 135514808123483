import React from 'react'
import { Link } from 'gatsby'

import AboutIcon from 'components/icons/AboutIcon'
import HomeIcon from 'components/icons/HomeIcon'
import LandscapeIcon from 'components/icons/LandscapeIcon'
import PlantIcon from 'components/icons/PlantIcon'
import SignIcon from 'components/icons/SignIcon'

const HeaderCharacter = (character, index) => {
  const characterClasses = [
    'page-title--color-1',
    'page-title--color-2',
    'page-title--color-3',
    'page-title--color-1',
    'page-title--color-2',
    'page-title--color-3',
    'page-title--color-2',
    'page-title--color-2',
    'page-title--color-1',
    'page-title--color-2',
  ]
  const className = characterClasses[index % characterClasses.length]
  return (
    <span key={index} className={className}>
      {character}
    </span>
  )
}

const Header = ({ headerText = '' }) => {
  const longHeader = headerText.length > 10
  const headerTextClass = `article__heading ${
    longHeader ? 'article__heading--medium' : 'article__heading--large'
  }`
  return (
    <oma-grid-row>
      <header className='header'>
        <h1 className={headerTextClass}>
          {[...headerText].map(HeaderCharacter)}
        </h1>
        <div className='navigation-bar'>
          <Link
            activeClassName='navigation-button--active'
            className='navigation-button'
            to='/'
            title='Hem'
          >
            <HomeIcon className='navigation-button__icon' />
          </Link>
          {/* <Link
            activeClassName='navigation-button--active'
            className='navigation-button'
            to='/'
            title='Om mig'
          >
            <AboutIcon className='navigation-button__icon' />
          </Link> */}
          <Link
            activeClassName='navigation-button--active'
            className='navigation-button'
            to='/galleri/djur-och-vaxter'
            title='Djur och växter'
          >
            <PlantIcon className='navigation-button__icon' />
          </Link>
          <Link
            activeClassName='navigation-button--active'
            className='navigation-button'
            to='/galleri/natur-och-kulturmiljoer'
            title='Natur- och kulturmiljöer'
          >
            <LandscapeIcon className='navigation-button__icon' />
          </Link>
          <Link
            activeClassName='navigation-button--active'
            className='navigation-button'
            to='/galleri/exempel-pa-arbeten'
            title='Exempel på arbeten'
          >
            <SignIcon className='navigation-button__icon' />
          </Link>
        </div>
      </header>
    </oma-grid-row>
  )
}

export default Header
