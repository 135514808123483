import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
          owner
          city
          phoneNumber
          email
        }
      }
    }
  `)

  return site.siteMetadata
}

export default SiteMetadata
